<template>
  <!-- Navbar Dark -->
  <nav
    class="navbar navbar-expand-lg position-fixed position-static w-100 navbar-dark bg-dark z-index-3 py-3 px-0">
    <div class="container">
      <div class="text-center d-block w-100 w-md-auto mb-2 mb-md-0">
        <a class="navbar-brand text-primary" href="/">
          <img src="@/assets/bang-bang-logo.png" class="h-100 me-1 me-md-3 mh-50px ms-auto"/>
          <h1 class="font-weight-bold h4 text-primary ms-auto d-inline">
            Лазертаг в Саратове
          </h1>
        </a>
      </div>
      <div class="col-auto text-center py-0 py-md-2 h-100 m-auto">
        <a class="btn btn-primary text-dark text-md my-3 my-md-auto " href="https://vk.com/bangbangsar?w=wall-205196470_3259" target="_blank">Сыграй бесплатно</a>
      </div>

      <div class="text-center w-100 w-md-auto" id="navigation">
        <div class="navbar-nav navbar-nav-hover ms-auto text-primary h4">
          <a href="tel:+79061551561" class="text-primary d-block d-lg-auto px-0 px-md-2">+7 (906) 155-15-61</a>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
export default {
  name: "MainNavigation",
};
</script>

<style scoped>

</style>
